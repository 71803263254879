






















































































































import { Component, Vue } from "vue-property-decorator";
import {
  deleteFromApi,
  getFromApi,
  postToApi,
  updateToApi,
} from "@/helpers/network_util";
import { AppUser } from "@/models/user";
import { ApiEndPoints } from "@/helpers/api_endpoints";
import Notifier from "@/helpers/notifier";
import Loader from "@/helpers/loader";
import agent from "@/helpers/agent";
import { BusinessDocument, DocumentStatus } from "@/models/businessDocument";

@Component
export default class UsersView extends Vue {
  private users: AppUser[] = [];
  pagination = {
    sortBy: "Name",
    descending: false,
    page: 0,
    rowsPerPage: 7,
    rowsNumber: 10,
  };
  loading = true;
  filter = "";
  columns = [
    {
      name: "userName",
      required: true,
      label: "UserName",
      align: "left",
      field: (row: AppUser) => row.userName,
      sortable: true,
      classes: "bg-grey-2 ellipsis",
      style: "max-width: 100px",
      headerClasses: "bg-primary text-white",
    },
    {
      name: "email",
      required: true,
      label: "Email",
      field: (row: AppUser) => row.email,
      format: (val: string) => `${val}`,
      sortable: true,
    },
    {
      name: "lockOutEnabled",
      required: true,
      label: "Lock Out Enabled?",
      field: (row: AppUser) => row.lockoutEnd,
    },
    {
      name: "lockoutEnd",
      required: true,
      label: "Is Locked?",
      field: (row: AppUser) => row.lockoutEnd,
    },
    {
      name: "role",
      required: true,
      label: "Roles",
      field: (row: AppUser) => row.role,
      sortable: true,
    },
    {
      name: "actions",
      required: true,
      label: "Actions",
      align: "right",
      field: "Actions",
    },
  ];
  roles = ["SuperAdmin", "Reviewer", "Manager", "User"];

  lockedIcon(row: AppUser): string {
    return row.lockoutEnd === null ? "lock" : "lock_open";
  }
  async mounted() {
    const roles = await agent.Roles.list();
    this.roles = roles.rows.map((s) => s.name);
    this.onRequest({
      pagination: this.pagination,
      filter: "",
    });
  }
  onRequest(props: any = {}) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const search = props.filter;
    this.loading = true;
    // get all rows if "All" (0) is selected
    const size = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage;
    agent.Users.list(search, descending, page, size, sortBy).then(
      (rows) => {
        //populate table
        this.users = rows.rows;
        this.pagination.rowsNumber = rows.rowCount;

        // don't forget to update local pagination object
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;

        // ...and turn of loading indicator
        this.loading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  changeRole(id: string, role: string) {
    agent.Users.changeRole(id, role)
      .then((user) => {
        const index = this.users.findIndex((i) => user.id === i.id);
        this.users[index] = user;
        Loader.hideLoader();
        Notifier.showPositive(`You have succesfully updated your profile`);
      })
      .catch(() => [
        Loader.hideLoader(),
        Notifier.showError("There was an error updating profile"),
      ]);
  }
  blockUser(user: AppUser) {
    this.loading = true;
    updateToApi<any>(
      `${ApiEndPoints.users}/block-user`,
      user.id,
      user.lockoutEnd === null
    )
      .then(async () => {
        Notifier.showInfo("User has been blocked successfully");
        this.onRequest({
          pagination: this.pagination,
          filter: "",
        });
      })
      .finally(() => (this.loading = false));
  }
  resetPassword(userName: string) {
    this.loading = true;
    // implement login
    agent.Users.resetPassword(userName)
      .then(() => {
        Notifier.showPositive(
          "Password reset succesfully, check your email for your new password."
        );
      })
      .finally(() => (this.loading = false));
  }
  deleteUser(id: string) {
    this.loading = true;
    deleteFromApi<AppUser>(ApiEndPoints.users, id)
      .then((d) => {
        const index = this.users.findIndex((i) => i.id == d.id);
        this.users.splice(index, 1);
        Notifier.showInfo(`User was successfully deleted`);
      })
      .finally(() => (this.loading = false));
  }
}
