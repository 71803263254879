import { render, staticRenderFns } from "./List.vue?vue&type=template&id=47cd47c2&"
import script from "./List.vue?vue&type=script&lang=ts&"
export * from "./List.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QInput,QIcon,QBtn,QTr,QTd,QToggle,QPopupEdit,QSelect});
