export enum ApiEndPoints {
  home = "Home",
  banks = "banks",
  vehicles = "Vehicles",
  users = "users",
  login = "users/login",
  register = "users/register",
  financialInstitutions = "FinancialInstitutions",
  claims = "claims",
}
